import React from "react";
import EntityBrand from "../components/FrontStatic/Entity-Brand";
import SEO from "../components/SEO";
import {graphql} from "gatsby";

const EntityBrandComponent = (props) =>
    <React.Fragment>
        <SEO {...(props?.data?.seo?.frontmatter || {})}/>
        <EntityBrand {...props}/>
    </React.Fragment>;

export default EntityBrandComponent;
export const query = graphql`
  query EntiryBrandQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "entity-brand-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;
