import React, { Component } from "react";
import FrontFooter from "../FrontStatic/common/Front-Footer";
import FrontWrapper from "./common/FrontWrapper";
import LazyImage from "../common/lazyImage";
class EntityBrand extends Component {
	render() {
		return (
			<FrontWrapper location={this.props.location}>
				<div className="scroll-content-wrapper" data-scrollbar>
					<div className="page-main page-current">
						<div className="page-toload legacy-page" data-bodyclassname="legacy">
							<main className="page-content" role="main">
								<section className="section-container our-story">
									<h2 className="show-text head padd-max js-type-animation-alt mb-50">
										Our Entities & Brands
									</h2>
									<div className="text-center m-b-60 mb-xs-20">
										<div className="entities-brands-main">
											<div className="entities-brands-lb">
												<div className="entities-logos">
													<div className="hk-entitial-logo m-b-40 d-flex align-items-center">
														<div className="enti-ic">
															<img
																src={require("../../../static/assets/svg/index/logo.svg")}
																alt=""
															/>
														</div>
														<div className="enti-ic-name">
															Hari Krishna
															<br />
															Exports Pvt. Ltd.
														</div>
													</div>
													<p>Loose diamond Manufacturer & Exporter</p>
												</div>

												<div className="entities-logos">
													<div className="entities-logos-two">
														<div className="mb-xs-20 m-b-40 d-flex align-items-center j-space-between">
															<div className="d-flex align-items-center j-space-between">

																<div className="designInc brandLogoSameSize">
																	<LazyImage
																		//src={require("../../assets/img/static/HkDesign.png")}
																		src={require("../../../static/assets/img/HKDesigns-Logo.png")}
																		alt=""
																	/>
																</div>
																<a className='brandLogoSameSize' href="http://www.mydiamondstory.com">
																	<LazyImage src={require("../../../static/assets/svg/index/diamond-story.svg")} alt="" />
																</a>
																<a className='brandLogoSameSize' href="">
																	<LazyImage src={require("../../../static/assets/img/static/Line-of-Love.jpg")} alt="" />
																</a>
																<a className='brandLogoSameSize' href="">
																<LazyImage
																	src={require("../../../static/assets/img/Moments-of-love.png")}
																	alt=""
																/>
																</a>
															</div>

														</div>
														<p>
															Diamond Jewellery Manufacturer &
															Exporter
														</p>
													</div>
													<div className="entities-logos-two">
														<div className="m-b-40 mb-xs-20 d-flex align-items-center j-space-between">
															<div className="designInc brandLogoSameSize">
																<LazyImage
																	//src={require("../../assets/img/static/HkJewels.png")}
																	src={require("../../../static/assets/img/HKJewels.png")}
																	alt=""
																/>
															</div>
															<a className='brandLogoSameSize' href=" http://www.Kisna.com">
																<LazyImage
																	src={require("../../../static/assets/svg/index/2.png")}
																	alt=""
																/>
															</a>
															
														</div>
														<p>
															Diamond Jewellery Manufacturer for Kisna
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div style={{ textAlign: "-webkit-center" }}>
										<img
											src={require("../../../static/assets/svg/index/down-arrow.svg")}
											alt=""
										/>
									</div>
								</section>

								<section className="entities-section">
									<div className="container d-flex align-items-center flex-wrap-xs">
										<div className="img-sec-en">
											<LazyImage
												src={require("../../../static/assets/img/static/hk-export.png")}
												className="width-full"
												alt=""
											/>
										</div>
										<div className="entities-detail">
											<div className="entities-detail-svg m-b-40 mb-xs-20">
												<LazyImage
													src={require("../../../static/assets/svg/index/logo-e.svg")}
													alt=""
												/>
											</div>
											<h2>Diamonds of your choice, just a click away!</h2>
											<p>
												A highly secured and robust online portal, hk.co is
												created specifically for the modern age clientele of
												Hari Krishna Exports. hk.co showcases a real-time
												inventory, with user friendly features such as
												computerized pairs, multilingual options, secured
												payment gateway and so on that support the
												customers’ online purchase from anywhere in the
												world.
											</p>
										</div>
									</div>
								</section>
								<section className="brand-section">
									<div className="container d-flex align-items-center flex-wrap-xs">
										<div className="entities-detail order-xs-2">
											<div className="entities-detail-svg m-b-40 mb-xs-20">
												<LazyImage
													src={require("../../../static/assets/svg/index/2.png")}
													alt=""
													width="30%"
												/>
											</div>
											<h2>
												Kisna–Jagao vishwas pyar ka, Paao Vishwas Parivar Ka
											</h2>
											<p>
												Kisna was launched by Hari Krishna Group in 2005 and
												has grown to become the largest distributed diamond
												jewellery brand in India today. Kisna provides
												customers a distinguished range of products
												including rings, earrings, pendants, necklaces etc.,
												made available through our 3,500+ jewellery outlets.
											</p>
										</div>
										<div className="img-sec-en order-xs-1">
											<img
												src={require("../../../static/assets/img/static/kishna-banner.jpg")}
												className="width-full"
												alt=""
											/>
										</div>
									</div>
								</section>

								<section className="entities-section">
									<div className="container d-flex align-items-center flex-wrap-xs">
										<div className="img-sec-en">
											<LazyImage
												src={require("../../../static/assets/img/diamond-story.jpg")}
												className="width-full"
												widthAuto={true}
												alt=""
											/>
										</div>
										<div className="entities-detail">
											<div className="entities-detail-svg m-b-40 mb-xs-20">
												<LazyImage
													src={require("../../../static/assets/svg/index/diamond-story.svg")}
													alt=""
													width="40%"
												/>
											</div>
											<h2>A unique bridal engagement collection</h2>
											<p>
												My Diamond Story is a really special and unique
												private labelbrand. It is dedicated to couples who
												are ready for a lifelong commitment and consists of
												bridal engagement rings crafted with brilliant
												solitaires and sheer diamonds set beautifully in
												timeless designs. The brand name goes beyond the
												product by encouraging couples to share their story
												of love and the precious moments.
											</p>
										</div>
									</div>
								</section>

								<FrontFooter />
							</main>
							<div className="preload" aria-hidden="true" />
						</div>
					</div>
					<div className="page-main page-next" aria-hidden="true" />
					<div className="page-main page-prev" aria-hidden="true" />
					<footer className="footer" role="contentinfo" />
				</div>
			</FrontWrapper>
		);
	}
}

export default EntityBrand;
